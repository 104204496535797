
const useFilter = (array,text,par) => {
  
  return array.filter((item) => {
    
    return par.some((newItem) => {
      return (
        item[newItem]
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) > -1
        );
      });
    });
    
    // const search_parameters = Object.keys(Object.assign({}, ...array));

    // return array.filter(
    //     (item) =>  par.some((parameter) =>
    //         item[parameter].toString().toLowerCase().includes(searchValue)
    //       )
    //   );

}


export default useFilter;
