import api from "../../api/index.js";
import useFetch from "../../hooks/useFetch.js";
import sliceArray from "../../utilities/sliceArray.js";

const Gallery = () => {

  let images = sliceArray(useFetch(api.gallery.links),10);

    return(<>
        <section className="site-section head-section"  id="gallery-section">
      <div className="container-fluid">
        <div className="row mb-4 justify-content-center">
          <div className="col-md-7 text-center">
            <h1 className="text-primary text-uppercase">Gallery</h1>
          </div>
        </div>
        
        <div className="row">

<a className="col-6 col-md-6 col-lg-4 col-xl-3 gal-item d-block"  data-aos="fade-up" data-aos-delay="100" href="../assets/media/images/photos/" data-fancybox="gal"><img src="../assets/media/images/photos/achieve (11).jpg" alt="" className="img-fluid rounded-0"/></a>
<a className="col-6 col-md-6 col-lg-4 col-xl-3 gal-item d-block"  data-aos="fade-up" data-aos-delay="100" href="../assets/media/images/photos/tm1.jpeg" data-fancybox="gal"><img src="../assets/media/images/photos/achieve (21).jpg" alt="" className="img-fluid rounded-0"/></a>
<a className="col-6 col-md-6 col-lg-4 col-xl-3 gal-item d-block"  data-aos="fade-up" data-aos-delay="100" href="../assets/media/images/photos/tm1.jpeg" data-fancybox="gal"><img src="../assets/media/images/photos/achieve (14).jpg" alt="" className="img-fluid rounded-0"/></a>
<a className="col-6 col-md-6 col-lg-4 col-xl-3 gal-item d-block"  data-aos="fade-up" data-aos-delay="100" href="../assets/media/images/photos/tm1.jpeg" data-fancybox="gal"><img src="../assets/media/images/photos/achieve (18).jpg" alt="" className="img-fluid rounded-0"/></a>
<a className="col-6 col-md-6 col-lg-4 col-xl-3 gal-item d-block"  data-aos="fade-up" data-aos-delay="100" href="../assets/media/images/photos/tm1.jpeg" data-fancybox="gal"><img src="../assets/media/images/photos/achieve (23).jpg" alt="" className="img-fluid rounded-0"/></a>
<a className="col-6 col-md-6 col-lg-4 col-xl-3 gal-item d-block"  data-aos="fade-up" data-aos-delay="100" href="../assets/media/images/photos/tm1.jpeg" data-fancybox="gal"><img src="../assets/media/images/photos/achieve (45).jpg" alt="" className="img-fluid rounded-0"/></a>
<a className="col-6 col-md-6 col-lg-4 col-xl-3 gal-item d-block"  data-aos="fade-up" data-aos-delay="100" href="../assets/media/images/photos/tm1.jpeg" data-fancybox="gal"><img src="../assets/media/images/photos/achieve (46).jpg" alt="" className="img-fluid rounded-0"/></a>
          
        </div>
      </div>
    </section>
    </>);

}

export default Gallery;