
import { useState } from "react";
import axios from 'axios';
import { NavLink } from "react-router-dom";

const Contact = () => {

  const [form, setForm] = useState({ name: '', email: '', message: '' });
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ show: false, message: '' })

  const handleForm = (name, value) => {

    Object.keys(form).forEach(det => {
      if (det === name) {
        setForm(form => {
          return { ...form, [name]: value }
        })
      }
    })

  }

  const handleSubmit = (e) => {

    e.preventDefault();

    try {
      console.log("Form Details", form);
      let response = axios.get('https://jsonplaceholder.typicode.com/todos');
      // setAlert(alert => {
      //   return { ...alert, show: true, message: 'Sent' }
      // })
      // console.log(response);
    }

    catch (e) {
      setErrors(e.message);
      console.log(errors);
    }

  }


  return (
    <>
      <div className="site-section bg-light head-section" id="contact-section">
        <div className="container">

          <div className="row">
            <div className="col-12 text-center mb-4">
              <h1 className="text-primary text-uppercase">Let's Connect</h1>
            </div>
          </div>

          <div className="row border p-2 shadow-lg">

            <div className="col-lg-4 mb-4 border pt-3 pb-3 bg-white col-12">

              <div className="row">
              <div className="mb-4">
              <img src="https://averobeauty.co.ke/assets/media/images/photos/mburu (3).jpg" className="promo-image border img-fluid" alt=" " height="200" />
              </div>
              </div>

              <div className="mb-lg-0 col-md-12 col-lg-12" >
                <p className="mb-0 font-weight-bold d-flex"> <i className='bx bxs-buildings' ></i> ADDRESS </p>
                <p className="border-bottom pb-2 "> *** </p>
              </div>
              <div className="mb-lg-0 col-md-12 col-lg-12 ">
                <p className="mb-0 font-weight-bold d-flex"> <i className='bx bxs-phone'></i> MOBILE </p>
                <p className="border-bottom pb-2"> *** </p>
              </div>
              <div className="mb-lg-0 col-md-12 col-lg-12">
                <p className="mb-0 font-weight-bold d-flex"><i className='bx bxs-envelope' ></i> EMAIL </p>
                <p className="border-bottom pb-2">tonymburu2@gmail.com</p>
              </div>

              <div className="mb-lg-0 col-md-12 col-lg-12 mt-3 d-flex align-items-center justify-content-center">
                <a href="https://www.facebook.com/profile.php?id=100091279610063&mibextid=ZbWKwL" className="pl-0 pr-3 text-decoration-none"><span className="icon-facebook"></span></a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/tony_mburu_?igsh=MWE2aXF3OHVjdmlhMg==" className="pl-3 pr-3 text-decoration-none"><span className="icon-instagram"></span></a>
                <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@tony_mburu_?_t=8jdDjjH1k4j&_r=1" className="pl-3 pr-3 text-decoration-none"><i className='bx bxl-tiktok'></i></a>
              </div>

            </div>

            <div className="col-lg-8">

              <form action="#" method="post" >
                
              <div className="py-3 text-dark text-uppercase fw-bold text-center">
                <h2 className="fw-bold">
                Taking the step to change yor tommorrow is all you need 
                </h2>
              </div>

                <div className="form-group row">
                  <div className={"col-md-12 "}>
                    <input type="text" className="form-control rounded-0" value={form.name}
                      onChange={(e) => handleForm(e.target.name, e.target.value)}
                      placeholder="Name" name="name" />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-12">
                    <input type="email" className="form-control rounded-0"
                      onChange={(e) => handleForm(e.target.name, e.target.value)}
                      value={form.email} placeholder="Email" name="email" />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-12">
                    <textarea name="message" className="form-control rounded-0"
                      onChange={(e) => handleForm(e.target.name, e.target.value)}
                      value={form.message} placeholder="Message" cols="30"
                      rows="10"></textarea>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-8 mx-auto">
                    <input type="submit" className="btn btn-block btn-primary text-white mx-auto shadow text-uppercase rounded" value="Send" onClick={handleSubmit} />
                  </div>
                </div>

                <div className={'btn btn-success btn-block mx-auto ' + (alert.show ? 'd-block' : 'd-none')}>
                  {alert.message}
                </div>

              </form>

            </div>

          </div>

        </div>
      </div>
    </>);

}

export default Contact;