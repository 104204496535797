import Navbar from "../navbar";
import { NavLink } from "react-router-dom";
import logo from "../../media/images/logo/tony/7.png";
const Header = () => {

  return (
  <>
   <div className="site-mobile-menu site-navbar-target">
          <div className="site-mobile-menu-header">
            <div className="site-mobile-menu-close mt-3">
              <span className="icon-close2 js-menu-toggle"></span>
            </div>
          </div>
          <div className="site-mobile-menu-body"></div>
    </div>
  
  <header className="site-navbar  site-navbar-target" role="banner">
    
  <div className="container">
    <div className="row align-items-center">

      <div className="col-6 col-xl-2">
        <h1 className="mb-0 site-logo">
          <NavLink to="/" className="h2 mb-0 logo-text">
            <img className="logo-image shadow-lg" src={logo} alt=""/>
        </NavLink>
        </h1>
      </div>

      <div className="col-12 col-md-10 d-none d-xl-block">
     <Navbar/>
      </div>

      <div className="col-6 d-inline-block d-xl-none ml-md-0 py-3 menu-parent ">
        <div 
          className="site-menu-toggle js-menu-toggle float-right mt-1"><span className="icon-menu h3"></span>
        </div>
      </div>

    </div>
  </div>

</header>

</>);
}

export default Header;