import { useState  } from "react";
import { NavLink } from "react-router-dom";
import api from "../../api/index.js";
import useFetch from "../../hooks/useFetch.js";
import sliceArray from "../../utilities/sliceArray.js";
import useFilter from "../../hooks/useFilter.js";



const Blogs = () => {

  let [searchValue,setSearchValue] = useState('');
  let [searchParam] = useState(["title","body"]);
  
  let blogs = useFilter(sliceArray(useFetch(api.blogs.links),10),searchValue,searchParam);
  
// paginate
  let [paginate, setpaginate] = useState(4);

  let load_more = (event) => {
  setpaginate((prevValue) => prevValue + 8);
  };
// paginate


    return(<>
    <section className="site-section shadow head-section bg-light pb-4" id="training-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-7 text-center">
            <h1 className="text-primary text-uppercase text-uppercase">Blogs</h1>
      
          </div>
        </div>

      </div>

      <div className="w-100 d-flex align-items-center justify-content-center ">
              <div className="btn btn-block-md btn-primary rounded-0">
                Unleash Your Ultimate Potential
              </div>
      </div>

    </section>

    <section className="site-section pt-5" id="blog-section">
      <div className="container">
        <div className="mb-4">
        <form action="#" method="post">

<div className="form-group row ">
  <div className="input-group col-md-8 w-100 rounded-0 search-field-parent ">
<div className="input-group-prepend rounded-0 p-0 bg-light">
<span className="input-group-text rounded-0 border-0 border-bottom bg-transparent px-2" id="search-icon">
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22" style={{fill:'rgba(0, 0, 0, .6)'}}><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path></svg>
</span>
</div>
<input type="text" value={searchValue}  className="form-control border-bottom border-0 rounded-0 shadow-xs bg-light" placeholder="Search" onChange={(event)=> setSearchValue(event.target.value)} />
</div>
</div>

</form>
        </div>
       
    
        <div className="row">

          {
            blogs
            .slice(0, paginate).map((blog,index) => {

              return  <div className="col-md-6 mb-4" key={index}>
              <div className="d-lg-flex blog-entry p-3">
                <figure className="mr-4">
                  <img src="../assets/media/images/sample/coach_1_sm.jpg" alt=""
                      className="img-fluid rounded"/>
                </figure>
                <div className="blog-entry-text">
                  {/* <h3><NavLink to="1" className='text-decoration-none'>{blog.title}</NavLink></h3> */}
                  <h3><NavLink to="1" className='text-decoration-none text-uppercase'>Redefining Your Business</NavLink></h3>
                  <span className="post-meta mb-3 d-block">April 17, 2019</span>
                  {/* <p>{blog.body}</p> */}
                  <p>Elevate your business to new heights by embracing a paradigm shift – redefine your business with innovation and strategic vision. Our program is tailored to help you reassess, reimagine, and revitalize your approach in the ever-evolving market landscape.</p>
                </div>
              </div>
            </div>

            })
          }
         
        </div>
        <button className="btn btn-primary btn-sm" onClick={load_more}>Load More</button>
      </div>

    </section>

   
    </>);

}

export default Blogs;