
const settings = {
    // dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 1,
               },
        },
        {
          breakpoint: 1000,
          settings: {
            arrows: false,
            slidesToShow: 2,
          },
        },

        {
            breakpoint: 1200,
            settings: {
              arrows: false,
              slidesToShow: 3,
            },
          },
      ],
  };
  export default settings;