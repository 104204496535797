import { useState  } from "react";
import { NavLink } from "react-router-dom";
import api from "../../api/index.js";
import useFetch from "../../hooks/useFetch.js";
import sliceArray from "../../utilities/sliceArray.js";
import useFilter from "../../hooks/useFilter.js";

import {actFetchProductsRequest,AddCart} from '../../redux/actions/index.js'
import {connect} from 'react-redux';


const Products = () => {

    let [searchValue,setSearchValue] = useState('');
    let [searchParam] = useState(["name","description","display","CPU"]);
    
    let products = useFilter(sliceArray(useFetch(api.products.links),10),searchValue,searchParam);
    
// paginate
    let [paginate, setpaginate] = useState(4);

    let load_more = (event) => {
    setpaginate((prevValue) => prevValue + 8);
    };
// paginate


    return(<>

    <section className="head-section site-section bg-light border-bottom shadow pb-4" id="training-section">
      <div className="container pb-0">
        <div className="row justify-content-center">
          <div className="col-md-7 text-center">
            <h1 className="text-primary text-uppercase text-uppercase fw-bold">PRODUCTS</h1>
          </div>
        </div>
      </div>

      <div className=" d-flex align-items-center justify-content-center pt-0">
              <div className="btn btn-primary rounded-0">
                Unleash Your Ultimate Potential
              </div>
      </div>
    </section>

    <section className="site-section pt-4 " id="blog-section">
      <div className="container px-2">
        <div className="pb-2" >
        
          <form action="#" method="post">

            <div className="form-group row ">
              <div className="input-group col-md-8 w-100 rounded-0 search-field-parent ">
         <div className="input-group-prepend rounded-0 p-0 bg-light">
         <span className="input-group-text rounded-0 border-0 border-bottom bg-transparent px-2" id="search-icon">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22" style={{fill:'rgba(0, 0, 0, .6)'}}><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path></svg>
         </span>
        </div>
        <input type="text" value={searchValue}  className="form-control border-bottom border-0 rounded-0 shadow-xs bg-light" placeholder="Search" onChange={(event)=> setSearchValue(event.target.value)} />
          </div>
            </div>

          </form>
        </div>


        <div className="row">

{
    products.slice(0, paginate).map((item,index) => (<div className="col-md-6 mb-4" key={index}>
              <div className="d-lg-flex blog-entry shadow-lg p-3">
                <figure className="mr-4 ">
                <img src="../assets/media/images/photos/mburu (1).png" alt=""
                      className="img-fluid "/>
                </figure>
                <div className="blog-entry-text">
                  <h3 className="text-uppercase">BlockChain</h3>
                  <p>
                  gain insights into how blockchain is disrupting industries such as finance, supply chain, and healthcare. Acquire a practical understanding of its applications, from enhancing transparency to streamlining processes.
                  </p>
                    <div className="w-100 d-flex align-items-center justify-content-end">
                      <span className="badge badge-primary reply" style={{cursor:'pointer'}} onClick={() => {
                        console.log("adding into cart");
                         AddCart(item);
                      }
                        }><i className='bx bxs-cart-add'></i></span>
                    </div>
                </div>
              </div>
            </div>
                    ))
}


  </div>
      <button className="btn btn-primary btn-sm" onClick={load_more}>Load More</button>
      </div>


    </section>
    </>);

}


const mapStateToProps = state => {
  return {
       _products: state._todoProduct,
     };
}
function mapDispatchToProps(dispatch){
  return{
      actFetchProductsRequest:()=> dispatch(actFetchProductsRequest()),
      AddCart:item=> dispatch(AddCart(item))
   
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Products)

// export default Products;