import api from "../../api/index.js";
import useFetch from "../../hooks/useFetch.js";
import sliceArray from "../../utilities/sliceArray.js";


const Testimonials = () => {

  let testimonials = sliceArray(useFetch(api.testimonials.links),4);

    return(<>

   <section className="site-section head-section shadow bg-light pb-3" id="training-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-7 text-center">
            <h1 className="text-primary text-uppercase text-uppercase"> What You Say </h1>
          </div>
        </div>
      </div>
    </section>

    <section className="site-section pt-5" id="blog-section">
      <div className="container">
    
        <div className="row g-1">
          {
            testimonials && testimonials.map((testimonial,index) => {
              return <div className="col-md-6 mb-4 " key={index}>
              <div className="d-lg-flex blog-entry p-3">
                <div className="blog-entry-text text-center border-bottom">
                  <h4 className="fw-bold text-uppercase">Jane Doe</h4>
                  <p>"Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live
                    the blind texts"</p>
                </div>
              </div>
            </div>
            })
          }

        </div>
      </div>

    </section>
   
    </>);

}

export default Testimonials;