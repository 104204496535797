// import { createStore, applyMiddleware } from 'redux';
import {legacy_createStore as createStore , applyMiddleware} from 'redux'

import {thunk} from 'redux-thunk';
import ShopApp from '../reducers/index'
const store =  createStore(ShopApp,applyMiddleware(thunk));


// import { configureStore } from '@reduxjs/toolkit';
// import rootReducer from './reducers';

// const store = configureStore({ reducer: rootReducer })

export default store;