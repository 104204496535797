let links = [
    {
        id : 1,
        category:'home',
        title:'Navigating the maze of personal development with insights from diverse sources',
        videoURL : 'https://www.youtube.com/embed/3bnH5L6gFZ4',
    },
    {
        id : 2,
        category:'home',
        title:'New to crypto? Learn how to capitalise on a bull run! 🤑👏',
        videoURL : 'https://www.youtube.com/embed/D4Cwz6yb6ek?list=UU3rjV78brxkHqaRlTg8xwew',
    },
    {
        id : 3,
        category:'home',
        title:'Why wait for retirement to chase your dreams? Start now and pave the way for a brighter future',
        videoURL : 'https://www.youtube.com/embed/2KDAZ6y9ZwY',
    },
    {
        id : 4,
        category:'home',
        title:'Finding a mentor is like turning on the GPS on the road trip to success. #SuccessJourney',
        videoURL : 'https://www.youtube.com/embed/QQUB-obI3Ho?list=UU3rjV78brxkHqaRlTg8xwew',
    },
    {
        id : 5,
        category:'home',
        title:'Let\'s change the norm. It\'s time to rethink our strategies',
        videoURL : 'https://www.youtube.com/embed/v5XU3_lPTIM?list=UU3rjV78brxkHqaRlTg8xwew',
    },
    {
        id : 6,
        category:'home',
        title:'Value in scarcity: Discover the power of Bitcoin',
        videoURL : 'https://www.youtube.com/embed/4RH2GnGwaEo?list=UU3rjV78brxkHqaRlTg8xwew',
    }
];

export default links;