import Home from "../pages/home/index.js";
import Courses from "../pages/courses/index.js";
import Seminars from "../pages/seminars/index.js";
import Products from "../pages/products/index.js";

import Articles from "../pages/shop/articles/index.js";
import MasterClass from "../pages/shop/master/index.js";

import Podcasts from "../pages/podcasts/index.js";
import Gallery from "../pages/gallery/index.js";
import Events from "../pages/events/index.js";
import Contact from "../pages/contact/index.js";
import Blogs from "../pages/blogs/index.js";
import Testimonials from "../pages/testimonials/index.js";
import About from "../pages/about/index.js";

import Product from "../components/pages/product/index.js";
import Course from "../components/pages/course/index.js";
import Seminar from "../components/pages/seminar/index.js";
import Blog from "../components/pages/blog/index.js";
import Event from "../components/pages/event/index.js";
import Cart from "../pages/cart/index.js";

import Interviews from "../pages/interviews/index.js";


import { Routes, Route } from "react-router-dom";


const Router = () => {
        return(
                <Routes>

                <Route path="/" element={<Home/>} /> 

                <Route  path="products">
                <Route index={true} element={<Products/>}/>
                <Route index={false} path=":id" element={<Product/>}/>
                </Route>

                <Route path="masterclass" element={<MasterClass/>} /> 
                <Route path="articles" element={<Articles/>} /> 

                <Route path="courses"  >
                <Route index={true} element={<Courses/>}/>
                <Route index={false} path=":id" element={<Course/>}/>
                </Route>

                <Route path="seminars">
                <Route index={true} element={<Seminars/>}/>
                <Route index={false} path=":id" element={<Seminar/>}/>
                </Route>

                <Route path="blogs">
                <Route index={true} element={<Blogs/>}/>
                <Route index={false} path=":id" element={<Blog/>}/>
                </Route>
                
                <Route path="events">
                <Route index={true} element={<Events/>}/>
                <Route index={false} path=":id" element={<Event/>}/>
                </Route>

                <Route path="cart" element={<Cart/>} /> 
                <Route path="about" element={<About/>} /> 
                <Route path="contact" element={<Contact/>} /> 
                <Route path="gallery" element={<Gallery/>} /> 
                <Route path="podcasts" element={<Podcasts/>} /> 
                <Route path="interviews" element={<Interviews/>} /> 
                <Route path="testimonials" element={<Testimonials/>} /> 
                
                </Routes>
        );

}

export default Router;