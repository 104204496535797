
const Subscribe = () => {
  return (
      <>
      <div id="myModal" className="modal fade bg-dark pt-0 mt-0" role="dialog">
      <div className="modal-dialog  border-none  rounded-0">
        <div className="modal-content bg-primary rounded px-2">
          <div className="modal-header border-0">
            <button type="button" className="btn-close btn-sm text-white bg-white" data-dismiss="modal"></button>
          </div>
          <div className="modal-body text-center pt-0 mt-0 p-2">
            <p className="text-uppercase fw-bold text-dark subscriber-head p-2 py-3 bg-dark shadow text-center"> CashFlow Course</p>
            <p className="fw-bold signup"> SIGN UP NOW !! </p>
            <div className="row">
              <div className="col-lg-12 "> 
                <form action="#" method="post">
                  <div className="form-group row">
                    <div className="col-md-12 mb-1 ">
                      <input type="text" className="form-control rounded-0" placeholder="Name"/>
                    </div>
                    <div className="col-md-12">
                      <input type="text" className="form-control rounded-0" placeholder="Email"/>
                    </div>
                  </div>
                  
                  <div className="form-group row">
                    <div className="col-md-12 mr-auto">
                      <input type="button" className="btn btn-block btn-primary text-white py-2 px-5 rounded-0 mx-auto" value="Submit"/>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Subscribe;