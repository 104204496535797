import { useState  } from "react";
import { NavLink } from "react-router-dom";
import api from "../../../api/index.js";
import useFetch from "../../../hooks/useFetch.js";
import sliceArray from "../../../utilities/sliceArray.js";
import useFilter from "../../../hooks/useFilter.js";


const Articles = () => {


    let [searchValue,setSearchValue] = useState('');
    let [searchParam] = useState(["name","description","display","CPU"]);
    
    let products = useFilter(sliceArray(useFetch(api.products.links),10),searchValue,searchParam);
    
// paginate
    let [paginate, setpaginate] = useState(4);

    let load_more = (event) => {
    setpaginate((prevValue) => prevValue + 8);
    };
// paginate


    return(<>

    <section className="head-section site-section bg-light border-bottom shadow pb-4" id="training-section">
      <div className="container pb-0">
        <div className="row justify-content-center">
          <div className="col-md-7 text-center">
            <h1 className="text-primary text-uppercase text-uppercase fw-bold">ARTICLES</h1>
    
          </div>
        </div>
      </div>

      <div className=" d-flex align-items-center justify-content-center pt-0">
              <div className="btn btn-primary rounded-0">
                Unleash Your Ultimate Potential
              </div>
      </div>
    </section>

    <section className="site-section pt-4 " id="blog-section">
      <div className="container px-2">
        <div className="pb-2" >
        
          <form action="#" method="post">

            <div className="form-group row ">
              <div className="input-group col-md-8 w-100 rounded-0 search-field-parent ">
         <div className="input-group-prepend rounded-0 p-0 bg-light">
         <span className="input-group-text rounded-0 border-0 border-bottom bg-transparent px-2" id="search-icon">
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22" style={{fill:'rgba(0, 0, 0, .6)'}}><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path></svg>
         </span>
        </div>
        <input type="text" value={searchValue}  className="form-control border-bottom border-0 rounded-0 shadow-xs bg-light" placeholder="Search" onChange={(event)=> setSearchValue(event.target.value)} />
          </div>
            </div>

          </form>
        </div>


        <div className="row">

{
products
.slice(0, paginate).map((item,index) => (

<div className="col-md-6 mb-4" key={index}>
              <div className="d-lg-flex blog-entry shadow-lg p-3">
              <figure className="mr-4 rounded-0">
       <img src="../assets/media/images/photos/mburu (5).jpg" alt=""
                className="img-fluid "/>
          </figure>
          <div className="blog-entry-text">
            <h3 className="fw-bold text-uppercase"> Unleash Your Potential </h3>
            <span className="post-meta d-block"> April 17, 2019 </span>
            <p>Unlocking the Secrets to Success with To...</p>
            <p>
            <NavLink to="blogs/1" className="fw-bold text-decoration-none ">
            Read More..
            </NavLink>
            </p>
          </div>
              </div>
            </div>
                    ))
                    
                    }
      </div>
      <button className="btn btn-primary btn-sm" onClick={load_more}>Load More</button>
      </div>

    </section>
    </>);

}

export default Articles;