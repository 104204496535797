import { useState  } from "react";
import api from "../../api/index.js";
import useFetch from "../../hooks/useFetch.js";
import sliceArray from "../../utilities/sliceArray.js";
import useFilter from "../../hooks/useFilter.js";

import podcastsLinks from "../../api/podcasts.js";

const Podcasts = () => {

  let [searchValue,setSearchValue] = useState('');
  let [searchParam] = useState(["title","body"]);
  
  let podcasts = useFilter(sliceArray(useFetch(api.podcasts.links),10),searchValue,searchParam);
  
// paginate
  let [paginate, setpaginate] = useState(4);

  let load_more = (event) => {
  setpaginate((prevValue) => prevValue + 8);
  };
// paginate

    return(<>
    <section className="site-section head-section shadow bg-light pb-4" id="training-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-7 text-center">
            <h1 className="text-primary text-uppercase text-uppercase">Podcasts</h1>
          </div>
        </div>
      </div>

      <div className="w-100 d-flex align-items-center justify-content-center ">
              {/* <div className="btn btn-block-md btn-primary rounded-0">
                Unleash Your Ultimate Potential
              </div> */}
              <p className="text-wrap text-center w-75">
              Unleashing your ultimate potential is not merely a destination but an ongoing expedition, where each step forward opens new horizons, revealing the extraordinary capacities that reside within you.
              </p>
      </div>
    </section>

    <section className="site-section shadow pt-5 pb-4" id="youtube-section">
        <div className="container px-2">
          <div className="row mb-3 mt-0">
          {
              podcastsLinks && podcastsLinks.map((link,index) => {
                  return <div className="col-md-6 mb-3 col-12" key={index}>
                  <iframe width="740" height="416" className="embed-responsive embed-responsive-16by9" src={link.videoURL
                  } title={link.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  <div className="pt-4">
                    <p className="text-center fw-bold text-capitalize"> {link.title} </p>
                  </div>
                </div>
              })
          }

          </div>
        </div>
      </section>


    </>);

}

export default Podcasts;