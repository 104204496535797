
import { NavLink } from "react-router-dom";


const Blog = () => {
    return (<>
    <div className="site-blocks-cover head-section" data-aos="fade">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-12 mt-lg-5 text-center">
            <h6 className="mb-4 text-uppercase text-primary fw-bold">Blog </h6>
            <h1 className="mb-4 text-uppercase text-dark">Mastering BlockChain  </h1>
            <p className="post-meta">&bull;  Published on April 17, 2019 &bull;</p>
          </div>
        </div>
      </div>
    </div>  
    
    <section className="site-section bg-light">
      <div className="container">
        <div className="embed-responsive embed-responsive-16by9">
        <video autoPlay={true} loop={true} controls={true} width="640" height="480">
                  <source type="video/mp4" src="https://endtest-videos.s3-us-west-2.amazonaws.com/documentation/endtest_data_driven_testing_csv.mp4"/>
                  </video>
        </div>
        <div className="row">

          <div className="col-md-11 blog-content">

            <p className="lead mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda nihil aspernatur nemo sunt, qui, harum repudiandae quisquam eaque dolore itaque quod tenetur quo quos labore?</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae expedita cumque necessitatibus ducimus debitis totam, quasi praesentium eveniet tempore possimus illo esse, facilis? Corrupti possimus quae ipsa pariatur cumque, accusantium tenetur voluptatibus incidunt reprehenderit, quidem repellat sapiente, id, earum obcaecati.</p>

            <blockquote><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident vero tempora aliquam excepturi labore, ad soluta voluptate necessitatibus. Nulla error beatae, quam, facilis suscipit quaerat aperiam minima eveniet quis placeat.</p></blockquote>

            <p>Eveniet deleniti accusantium nulla natus nobis nam asperiores ipsa minima laudantium vero cumque cupiditate ipsum ratione dicta, expedita quae, officiis provident harum nisi! Esse eligendi ab molestias, quod nostrum hic saepe repudiandae non. Suscipit reiciendis tempora ut, saepe temporibus nemo.</p>
            <p>Accusamus, temporibus, ullam. Voluptate consectetur laborum totam sunt culpa repellat, dolore voluptas. Quaerat cum ducimus aut distinctio sit, facilis corporis ab vel alias, voluptas aliquam, expedita molestias quisquam sequi eligendi nobis ea error omnis consequatur iste deleniti illum, dolorum odit.</p>
            <p>In adipisci corporis at delectus! Cupiditate, voluptas, in architecto odit id error reprehenderit quam quibusdam excepturi distinctio dicta laborum deserunt qui labore dignissimos necessitatibus reiciendis tenetur corporis quas explicabo exercitationem suscipit. Nisi quo nulla, nihil harum obcaecati vel atque quos.</p>
            <p>Amet sint explicabo maxime accusantium qui dicta enim quia, nostrum id libero voluptates quae suscipit dolor quam tenetur dolores inventore illo laborum, corporis non ex, debitis quidem obcaecati! Praesentium maiores illo atque error! Earum, et, fugit. Sint, delectus molestiae. Totam.</p>

            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa iste, repudiandae facere aperiam sapiente, officia delectus soluta molestiae nihil corporis animi quos ratione qui labore? Sint eaque perspiciatis minus illum.</p>
            <p>Consectetur porro odio quod iure quaerat cupiditate similique, dolor reprehenderit molestias provident, esse dolorum omnis architecto magni amet corrupti neque ratione sunt beatae perspiciatis? Iste pariatur omnis sed ut itaque.</p>
            <p>Id similique, rem ipsam accusantium iusto dolores sit velit ex quas ea atque, molestiae. Sint, sed. Quisquam, suscipit! Quisquam quibusdam maiores fugiat eligendi eius consequuntur, molestiae saepe commodi expedita nemo!</p>

            <div className="pt-5 row ">

            <div className="sidebar-box border col-lg-4 ">
              <div className="categories">
                <h3 className="text-primary text-uppercase fw-bold">Topic</h3>
                <li>Coaching </li>
                <li>Counselling</li>
               
              </div>
            </div>
      
              
              <div className="comment-form-wrap pt-4 col-lg-8">
                <h2 className="mb-3 text-dark">Leave a comment</h2>
                <form action="#" className="">
                  <div className="form-group">
                    <label htmlFor="name">Name *</label>
                    <input type="text" className="form-control rounded-0" id="name"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email *</label>
                    <input type="email" className="form-control rounded-0" id="email"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea name="" id="message" cols="30" rows="10" className="form-control rounded-0"></textarea>
                  </div>
                  <div className="form-group">
                    <input type="submit" value="Post Comment" className="btn btn-primary rounded-0"/>
                  </div>

                </form>
              </div>


            </div>

          </div>

          <div className="col-lg-12 row ">
          
            <div className="col-lg-6">
              <img src="../../assets/media/images/photos/tm1.jpeg" alt="" className="img-fluid"/>
            </div>

            <div className="col-lg-6 text-align-left">
            <h3 className=" text-dark fw-bold mt-3">About The Author</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus itaque, autem necessitatibus voluptate quod mollitia delectus aut, sunt placeat nam vero culpa sapiente consectetur similique, inventore eos fugit cupiditate numquam!</p>
            
              <NavLink to="/about">
                <div className="btn btn-primary btn-sm rounded-0 text-uppercase">Read More <i className='bx bx-chevron-right'></i> </div>
                </NavLink>
               
            </div>

          </div>
        </div>
      </div>
    </section>

    
    
     </>);
}

export default Blog;