import { NavLink } from "react-router-dom";
import api from "../../api/index.js";
import useFetch from "../../hooks/useFetch.js";
import sliceArray from "../../utilities/sliceArray.js";

import interviews from "../../api/interviews.js";

const Interviews = () => {

  let events = sliceArray(useFetch(api.events.links),10);

    return(
    <>
     <section className="site-section head-section bg-light shadow pb-4" id="training-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-7 text-center">
            <h1 className="text-primary text-uppercase text-uppercase">INTERVIEWS</h1>
          </div>
        </div>

      </div>
    </section>


    <section className="site-section shadow pt-5 pb-4" id="youtube-section">
        <div className="container px-2">
          <div className="row mb-3 mt-0">
          {
              interviews && interviews.map((link,index) => {
                  return <div className="col-md-6 mb-3 col-12" key={index}>
                  <iframe width="740" height="416" className="embed-responsive embed-responsive-16by9" src={link.videoURL
                  } title={link.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  <div className="pt-4">
                    <p className="text-center fw-bold text-capitalize"> {link.title} </p>
                  </div>
                </div>
              })
          }

          </div>
        </div>
      </section>



    </>);

}

export default Interviews;