let interviews = [
    {
        id:1,
        title:'What you need to know about blockchain on #MorningCafe',
        videoURL : 'https://www.youtube.com/embed/44QAGsajkKs'
    },
    {
        id:2,
        title:'K24 TV LIVE | MONEY AND FINANCE #K24ThisMorning',
        videoURL : 'https://www.youtube.com/embed/wOxVlQLGLyw'
    }
]

export default interviews;
