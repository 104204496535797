export default function sliceArray(arr,limit) {

    if (!limit) { return arr; }

    else {
        if(arr.length > 1) return arr.slice(0,limit)
       }

    return arr;
}

