import Subscribe from "../widgets/modal/index.js";
import Loader from "../widgets/loader/index.js";

import Header from "../partials/header/index.js";
import Footer from "../partials/footer/index.js";

const Layout = ( { children }) => {
   return (
      <>
         
      <Subscribe/>
      
      {/* <Loader/> */}
    
      <div className="site-wrap">
         <Header/>
         {children}
         <Footer/>
      </div> 

      </>
         );
}

export default Layout; 