
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/index.js';
import ScrollToTop from './utilities/scrollToTop.js';
import { Provider } from "react-redux";
import store from "./redux/store/index.js";
import { BrowserRouter as Router } from "react-router-dom";

document.addEventListener('DOMContentLoaded', function() {
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
          <Provider store={store}>
          <React.StrictMode>
          <Router>
            <ScrollToTop/>
            <App />
          </Router>
          </React.StrictMode>
          </Provider> );
      });
