import { useState , useEffect } from "react";
import axios from "axios";

const useFetch = (arr) => {

      const [data,setData] = useState([])

      useEffect(() => { 

        const getData = async() => {

          if(arr.length > 1) {
            let requests = await arr.map((obj) => axios.get(obj.link));
            await axios.all(requests).then((responses) => {
            let results = [];

             responses.forEach((response) => {
             results.push(response.data);
              })

            setData(results);

            });
          }

          else {
            await axios.get(arr[0].link).then((response) => { 
              setData(response.data);
            })
          }

        }

        getData();

       } , [arr]);

      return data;
}

export default useFetch;