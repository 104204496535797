import { NavLink } from "react-router-dom";

const Footer = () => {

     let year = new Date().getFullYear();
  
  return (
      <footer className="site-footer">
       <div className="container">
        <div className="row">
          <div className="col-md-4">

              <p className="fw-bold text-white">
                TONY M INTERNATIONAL <br/>
             
              </p>

              <p className="copyright">
              <small>
                Copyright &copy; {year}
              </small>
              </p>

              <NavLink to="/contact" className="footer-heading mb-4 text-decoration-none"> LET'S CONNECT <i className='bx bx-chevron-right'></i> </NavLink>

          </div>

          <div className="col-md-3 ml-auto">
            <h2 className="footer-heading mb-4">Quick Links</h2>
            <ul className="list-unstyled">
              <li><NavLink to="/interviews" className="smoothscroll text-decoration-none">Interviews</NavLink></li>
              <li><NavLink to="/podcasts" className="smoothscroll text-decoration-none">Podcasts</NavLink></li>
              <li><NavLink to="/about" className="smoothscroll text-decoration-none">About Tony</NavLink></li>
              <li><NavLink to="/testimonials" className="smoothscroll text-decoration-none">Testimonials</NavLink></li>
              {/* <li><NavLink to="/blogs" className="smoothscroll text-decoration-none">Blogs</NavLink></li>
              <li><NavLink to="/events" className="smoothscroll text-decoration-none">Events</NavLink></li>
              <li><NavLink to="/courses" className="smoothscroll text-decoration-none">Courses and Training</NavLink></li> */}
            </ul>
          </div>

          <div className="col-md-4">

            <div className="mb-5">
              <h2 className="footer-heading mb-4"> Follow Me </h2>
              <a href="https://www.facebook.com/profile.php?id=100091279610063&mibextid=ZbWKwL" className="pl-0 pr-3 text-decoration-none"><span className="icon-facebook"></span></a>
              <a target="_blank" href="https://www.instagram.com/tony_mburu_?igsh=MWE2aXF3OHVjdmlhMg==" className="pl-3 pr-3 text-decoration-none"><span className="icon-instagram"></span></a>
              <a target="_blank" href="https://www.tiktok.com/@tony_mburu_?_t=8jdDjjH1k4j&_r=1" className="pl-3 pr-3 text-decoration-none"><i className='bx bxl-tiktok'></i></a>
            </div>

            <h2 className="footer-heading mb-4"> Subscribe Newsletter </h2>
            <form action="#" method="post" className="footer-subscribe">
              <div className="input-group mb-3">
                <input type="text" className="form-control border-secondary text-white bg-transparent rounded-0"
                  placeholder="Enter Email" aria-label="Enter Email" aria-describedby="button-addon2"/>
                <div className="input-group-append">
                  <button className="btn btn-sm btn-primary text-black rounded-0" type="button" id="button-addon2">Send</button>
                </div>
              </div>
            </form>

          </div>
        </div>

      </div>
    </footer>
  );
}






export default Footer;