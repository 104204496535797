

const api = {

      home : {
       "links" :  [  
         {  link : "https://jsonplaceholder.typicode.com/photos",   limit:6 },
         {  link : "https://jsonplaceholder.typicode.com/posts",    limit:6 },
         {  link : "https://jsonplaceholder.typicode.com/todos",    limit:6 },
         {  link : "https://jsonplaceholder.typicode.com/comments", limit:6 },
        ]
      },

      products : {
        "links" : [ { link : "https://dummyapi.online/api/products"} ]
      },

      courses : {
        "links" : [ { link : "https://jsonplaceholder.typicode.com/posts"} ]
      },

      blogs : {
        "links" : [ { link : "https://jsonplaceholder.typicode.com/posts"} ]
      },

      events : {
        "links" : [ { link : "https://jsonplaceholder.typicode.com/posts"} ]
      },

      podcasts : {
        "links" : [ { link : "https://jsonplaceholder.typicode.com/posts"} ]
      },

      seminars : {
        "links" : [ { link : "https://jsonplaceholder.typicode.com/posts"} ]
      },

      gallery : {
        "links" : [ { link : "https://jsonplaceholder.typicode.com/posts"} ]
      },

      testimonials : {
        "links" : [ { link : "https://jsonplaceholder.typicode.com/posts"} ]
      },


}

export default api;