import { NavLink } from "react-router-dom";
import Slider from "react-slick";

import api from "../../api/index.js";
import useFetch from "../../hooks/useFetch.js";
import settings from "../../utilities/carousel-settings.js";

// import headerImage from "../../media/images/pexels/pexels1.jpg";
import headerImage from "../../media/images/photos/audience (3).jpg";

import links from "../../api/youtube.js";

const Home = () => {

const [products,courses,events,blogs] = useFetch(api.home.links);

  return (
      <>
      <section className="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="header-image bg-light d-flex">
                <img 
                 src={headerImage}
                 className="promo-image" alt=" " />
                <div className="overlay-text shadow">
                 <h1 className="fw-bold"> YOU ARE DESTINED FOR GROWTH AND SUCCESS </h1> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <section className="site-section shadow-lg p-3 pt-5" id="about-section">
        <div className="container p-0">
          <div className="row">
  
            <div className="col-lg-12 featured-text mt-2"> 
              <h2 className="text-black text-uppercase fw-bold"> Featured In </h2>
            </div>
  
            <div className="d-flex flex-wrap align-items-center
            justify-content-evenly mt-3 mb-3 border-bottom py-2 bg-light">
  
              <img className="featured-logo" src="https://averobeauty.co.ke/assets/media/images/featured/logo-header.svg" alt="" />
              <img className="featured-logo " src="https://averobeauty.co.ke/assets/media/images/featured/ktn.png" alt=""/>  
              <img className="featured-logo " src="https://averobeauty.co.ke/assets/media/images/featured/tv47.jpeg" alt=""/>  
              <img className="featured-logo " src="../../assets/media/images/featured/k24.png" alt="Here"/>  
  
            </div>
  
          </div>
          <div className="row "> 
            <div className="col-12 mb-3">
              <h3 className="text-black fw-bold"> Hello there, <br/>
                 I'm <span className="text-uppercase text-primary"> Tony Mburu </span></h3>
            </div>
  
            <div className="col-md-6 mb-5 p-2">
              <div className="mb-4">
              <img src="https://averobeauty.co.ke/assets/media/images/photos/mburu (2).jpg" className="promo-image border img-fluid" alt=" " height="200" />
              </div>
              <h4 className="text-uppercase fw-bold text-primary"> Transformational Coach | Business Strategist | Blockchain Technology Expert </h4>
              <p className="tony-text">
              As a success addict with a passion for personal development and blockchain technology,extensive experience in cryptocurrencies and a proven track record in empowering individuals, I'm here to guide you towards your next level of fulfillment.
              </p>
            </div>

            <div className="col-md-6 ml-auto events-container col-12" >
              <h1 className="text-secondary mb-4 text-uppercase font-weight-bold"> Your Fast Track to Time and Financial Freedom </h1>
              <ul className="unstyled">

                      <li className="mb-2" >
                        <h4 className="text-black m-0 text-uppercase fw-bold"> Coaching </h4>
                        <p className="pt-2">
                        Emphasizing on self-awareness, empowering people to take responsibility for their choices and actions and encourage them to view challenges as opportunities for growth and learning, fostering resilience and adaptability. 
                        </p>
                      </li>

                      <li className="mb-2" >
                        <h4 className="text-black m-0 text-uppercase fw-bold"> Business  Strategy </h4>
                        <p className="pt-2">
                        In a world of constant change, businesses that cultivate a robust and agile strategy are better positioned to thrive. Strategic thinking is not a one-time task but an ongoing process that requires continuous evaluation and adjustment.
                        </p>
                      </li>

                      <li className="mb-2" >
                        <h4 className="text-black m-0 text-uppercase fw-bold"> Blockchain  Technology </h4>
                        <p className="pt-2">
                        Embark on a transformative journey into the world of blockchain with our cutting-edge blockchain class. Whether you're a novice or an industry professional, this course is designed to demystify the complexities of blockchain technology and empower you with practical knowledge.
                        </p>
                      </li>

              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="site-section shadow pt-5 pb-4" id="youtube-section">
        <div className="container px-2">
          <div className="row mb-3 mt-0">
          {
              links && links.map((link,index) => {
                  return <div className="col-md-4 mb-3 col-12" key={index}>
                  <iframe width="740" height="416" className="embed-responsive embed-responsive-16by9" src={link.videoURL
                  } title={link.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  <div className="pt-4">
                    <p className="text-center fw-bold text-capitalize"> {link.title} </p>
                  </div>
                </div>
              })
          }

          <a href="https://www.youtube.com/@tony_mburu_" rel="noreferrer"  target="_blank" className="text-decoration-none">
          <div className="btn btn-dark rounded-0 d-flex align-items-center justify-content-center text-align-center mx-auto py-3 text-uppercase"> More <i className='bx bx-chevron-right home-icon'></i>
          </div>
          </a>

          </div>
        </div>
      </section>





      <section className="site-section shadow pt-5 pb-4" id="blog-section">
        <div className="container px-2">
        <div className="row mb-3 justify-content-center">
            <div className="col-md-7 text-center">
              <h2 className="text-black text-uppercase fw-bold"> INTERVIEWS </h2>
            </div>
        </div>


     <div className="row">

      <div className="col-md-4">
        <h3 className="fw-bold text-uppercase text-center">
        What you need to know about blockchain on #MorningCafeTV47
        </h3>
      <p className="py-1">
      "Blockchain technology revolutionizes digital transactions by creating secure, decentralized ledgers.Learn how blockchain is  reshaping industries and what it means for the future of finance, security, and beyond."
      </p>
        <NavLink to="interviews" className="text-decoration-none">
          <div className="btn btn-dark rounded-0 d-flex align-items-center justify-content-center text-align-center text-white py-2">
          MORE <i className='bx bx-chevron-right home-icon'></i>
          </div>
          </NavLink>
      </div>

     <div className="col-md-8 ">
         <div className="embed-responsive embed-responsive-16by9">
          <iframe width="700" height="416" src="https://www.youtube.com/embed/44QAGsajkKs" title="What you need to know about blockchain on #MorningCafe" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
      </div>
    </div>

        </div>
        </section>




      <section className="site-section shadow pb-3 pt-5" id="podcast-section">
        <div className="container">
        <div className="row mb-3 justify-content-center">
            <div className="col-md-7 text-center">
              <h2 className="text-black text-uppercase fw-bold text-wrap text-center"> Listen.Learn.Lead </h2>
            </div>
        </div>

        <div className="row">

          <div className="col-md-6">
          <div className="embed-responsive embed-responsive-16by9">
          <iframe width="740" height="416" src="https://www.youtube.com/embed/Qi-wuO9w5-E" title="The Formula for Success and becoming the Highest and Truest Expression of Yourself" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
          </div>
          <div className="col-md-6">

            <h3 className="text-capitalize pt-4 text-center">
            The Formula for Success and becoming the Highest and Truest Expression of Yourself
            </h3>

          <p className="py-2">
          Authenticity is the secret ingredient. Being the highest and truest expression of yourself means aligning actions with core beliefs. Define success on your terms, not society's expectations. Embrace authenticity, for it magnetically attracts opportunities and genuine connections.
          
          </p>

          <NavLink to="podcasts" className="text-decoration-none">
          <div className="btn btn-dark rounded-0 d-flex align-items-center justify-content-center text-align-center text-white py-2">
          MORE <i className='bx bx-chevron-right home-icon'></i>
          </div>
          </NavLink>

          </div>

        </div>
       

        </div>
      </section>

      <section className="site-section shadow pb-3 pt-5" id="podcast-section">
        <div className="container">
        <div className="row mb-3 justify-content-center">
            <div className="col-md-7 text-center w-75">
              <h2 className="text-black text-uppercase fw-bold"> ON QUEST TO TRANSFORM LIVES </h2>
              <p className="text-wrap">
             Embarking on a relentless quest to transform lives is a noble pursuit that encapsulates the essence of compassion and purpose reflecting an unwavering commitment to making a positive impact on individuals and communities, fostering a ripple effect of change. 
              </p>
            </div>
        </div>

        <div className="row g-3">

          <div className="col-md-3">
          <img src="../../assets/media/images/photos/achieve (46).jpg" className=" img-fluid" alt=" "  />
          </div>
          <div className="col-md-3">
          <img src="../../assets/media/images/photos/achieve (40).jpg" className=" img-fluid" alt=" " />
          </div>
          <div className="col-md-3">
          <img src="../../assets/media/images/photos/achieve (45).jpg" className=" img-fluid" alt=" " />
          </div> 
          <div className="col-md-3">
          <img src="../../assets/media/images/photos/achieve (12).jpg" className=" img-fluid" alt=" "  />
          </div>

        </div>
        </div>
      </section>

      <section className="site-section shadow pb-3 pt-5" id="services-section">
        <div className="container">
          <div className="row mb-3 justify-content-center">
            <div className="col-md-7 text-center">
              <h2 className="text-black text-uppercase fw-bold"> Success Stories </h2>
              <p>Consistent effort is the engine that drives success. Small, intentional actions compound over time, leading to significant achievements. Celebrate milestones, and acknowledge that success is a journey, not a destination.</p>
            </div>
          </div>
  
          <div className="">

          <Slider {...settings} >
                {
                    [1,2,3,4,5] && [1,2,3,4,5].slice(0,5).map((product,index) => {

                        return  <div className="service text-center bg-light border-right p-3" key={index}>
                        <h5 className="text-black mb-3 text-uppercase fw-bold"> John Doe  </h5>
                        <p> " Tony has helped me rediscover my purpose in life and now I'm winning in my quest to financial freedom " </p>
                      </div>
                    })
                }
            </Slider>

          </div>

          <div className="row mt-2">

            <div className="col-lg-4 mx-auto py-2">
            <NavLink to="testimonials" className="text-decoration-none">
              <div className="btn btn-primary rounded-0 d-flex align-items-center justify-content-center text-align-center text-white py-2">
            MORE <i className='bx bx-chevron-right home-icon'></i>
            </div>
            </NavLink>
            </div>

          </div>
         

        </div>
  
      </section>

      <section className="site-section shadow pb-3 pt-5" id="punchline-section">
        <div className="container">
        <div className="row">
          <div className="col-md-11 d-flex align-items-center justify-content-center mx-auto flex-wrap">
          <img src="../../assets/media/images/photos/achieve (19).jpg" className="" alt=" "  height="300"/>
          <h1 className="fw-bold text-dark text-wrap w-50 m-5 text-uppercase text-center">Take charge of your life <br/> now or never</h1>
          </div>
        </div>
        </div>
      </section>

          </>
  );
  
}

export default Home;