let podcasts = [
    {
        id:1,
        title:'How to Escape the Rat Race and gain Financial Freedom',
        videoURL : 'https://www.youtube.com/embed/gEk9ABuYD28'
    },
    {
        id:2,
        title:'Blockchain Technology and Crypto Currencies, WE ARE IN SEASON!',
        videoURL : 'https://www.youtube.com/embed/1TQGk9I4Awk'
    },
    {
        id:3,
        title:'The Formula for Success and becoming the Highest and Truest Expression of Yourself',
        videoURL : 'https://www.youtube.com/embed/Qi-wuO9w5-E'
    },
    {
        id:4,
        title:'The crypto Bull Run, How ready are you for the next Market Cycle?',
        videoURL : 'https://www.youtube.com/embed/7G2Z5GPXc1Q'
    },
];


export default podcasts;