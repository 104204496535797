import { NavLink } from "react-router-dom";

const Navbar = () => {

  return (<>
    <nav className="site-navigation position-relative text-right" role="navigation">

      <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block ">
        {/* <li><NavLink to="/products" className="nav-link fw-semibold"> PRODUCTS </NavLink></li>
        <li className="has-children">
          <a href="#" className="nav-link fw-semibold"> SHOP </a>
          <ul className="dropdown shadow">
            <li><NavLink to="/masterclass" className="nav-link">Master Class</NavLink></li>
            <li><NavLink to="/articles" className="nav-link">Articles</NavLink></li>
          </ul>
        </li> */}
        <li className="has-children">
          <a href="#" className="nav-link fw-semibold"> RESOURCES </a>
          <ul className="dropdown shadow">
            <li><NavLink to="/interviews" className="nav-link">Interviews</NavLink></li>
            <li><NavLink to="/podcasts" className="nav-link">Podcasts</NavLink></li>
            {/* <li><NavLink to="/products" className="nav-link">Products</NavLink></li> */}
            {/* <li><NavLink to="/masterclass" className="nav-link">Master Class</NavLink></li> */}
            {/* <li><NavLink to="/articles" className="nav-link">Articles</NavLink></li> */}
            {/* <li><NavLink to="/courses" className="nav-link">Courses and Training</NavLink></li> */}
            {/* <li><NavLink to="/seminars" className="nav-link">Seminars</NavLink></li> */}
            <li><NavLink to="/gallery" className="nav-link">Gallery</NavLink></li>
            {/* <li><NavLink to="/blogs" className="nav-link">Blogs</NavLink></li> */}
          </ul>
        </li>
        {/* <li><NavLink to="/events" className="nav-link fw-semibold"> EVENTS </NavLink></li> */}
        {/* <li><a target="_blank" href="http://localhost:8000/" className="nav-link fw-semibold">Member</a></li> */}
        <li><NavLink to="/contact" className="nav-link fw-semibold"> CONTACT </NavLink></li>
        {/* <li><NavLink to="/cart" className="nav-link fw-semibold"> <i className='bx bxs-cart'></i> </NavLink></li> */}
      </ul>

    </nav>

  </>);

}

export default Navbar;